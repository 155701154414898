function makeUrlRelative(sourceUrl?: string | null) {
  if (sourceUrl) {
    const { appUrl } = useRuntimeConfig().public;
    const url = new URL(sourceUrl, appUrl);

    return url.pathname + url.search + url.hash;
  }
}

export default makeUrlRelative;
